<template>
  <div class="result">
    <van-icon :name="status ? 'passed' : 'close'" :class="status.toString()" />
    <p>{{ msg }}</p>
    <small v-if="!status">{{ rid }}</small>
  </div>
</template>
<script>
import { Icon } from "vant";

export default {
  data() {
    return {
      msg: "未知错误信息",
      status: true,
      rid: null,
    };
  },
  async created() {
    this.msg = this.$route.params.msg;
    this.rid = this.$route.params.rid;
    this.status = this.rid === "success";
    document.title = this.status ? "成功" : "失败";
  },
  methods: {
    selectEn() {},
  },
  components: {
    [Icon.name]: Icon,
  },
};
</script>
<style lang="less">
.result {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  i {
    font-size: 12vw;
    margin-top: 30vh;
  }
  .false {
    color: red;
  }
  .true {
    color: green;
  }
}
</style>
